import React, { Fragment, useEffect, useState } from "react";
import "./Ernaehrungscoaching.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlockControls from "../components/BlockControls";
import SocialIcons from "../components/SocialIcons";
import ContentBlock1 from "../components/ContentBlock1";
import ContentBlock2 from "../components/ContentBlock2";
import ScrollToTop from "../components/ScrollToTop";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ContentBlockGallery from "../components/ContentBlockGallery";
import Reviews from "../components/Reviews";
import NewsletterFormular from "../components/NewsletterFormular";

function Ernaehrungscoaching() {
    const blockControlsData = [
        {
            titel: "Kostenfreies Erstgespräch",
            blockId: "b1",
        },
        {
            titel: "Ernährungscoaching",
            blockId: "b2",
        },
        {
            titel: "Infos zu Ernährungscoaching",
            blockId: "b3",
        },
        {
            titel: "Vorteile von Ernährungscoaching",
            blockId: "b4",
        },
        {
            titel: "Wo findet Ernährungscoaching statt",
            blockId: "b5",
        },
        {
            titel: "Schnelle Rezepte",
            blockId: "b6",
        },
        {
            titel: "Ernährungscoaching Impressionen",
            blockId: "b7",
        },
        {
            titel: "Newsletter",
            blockId: "newsletter",
        },
        {
            titel: "Kontakt",
            blockId: "b9",
        },
    ];

    const [schema, setSchema] = useState("light");
    const [schemaHeader, setSchemaHeader] = useState("light");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setSchema("dark");
            } else {
                setSchema("light");
            }
        });
    }, []);

    const [rezptSliderItems, setRezeptSliderItems] = useState([]);

    const directusURL = "https://admin.carolinpfeffer.de";

    useEffect(() => {
        const options = { method: "GET" };

        fetch(directusURL + "/items/Blogbeitraege?filter[status]=published&sort=-Datum&limit=4&filter[Kategorie]=Rezept&page=1", options)
            .then((response) => response.json())
            .then((response) => {
                setRezeptSliderItems(response["data"]);
            })
            .catch((err) => console.error(err));
    }, []);

    const openDialog = (targetDialog) => {
        document.getElementById(targetDialog).showModal();
        document.body.style.overflowY = "hidden";
    };

    return (
        <Fragment>
            <Helmet>
                <title>Ernährungscoaching | Carolin Pfeffer</title>
                <meta
                    name="description"
                    content="▶▶ Jetzt zum Ernährungscoaching anmelden! Gruppen- oder Einzelberatung ✔ langfristig Gesund durch richtige Erhährung ✔ Traumfigur erreichen ✔ vieles mehr!"
                ></meta>
                <meta
                    name="keywords"
                    content="Ernährungscoaching,ernährungsberaterin,ernährungsberater,ernährung beratung,Ernährungscoaching vegan,Ernährungscoaching vegetarisch,Ernährungscoaching figur,ernährung vegan,Ernährungscoaching thüringen,ernährung fitness,ernährung sport"
                ></meta>
            </Helmet>
            <ScrollToTop />
            <Header schema={schemaHeader} />
            <SocialIcons schema={schema} />
            <BlockControls blockControlsData={blockControlsData} schema={schema} />
            <ContentBlock1
                titel="Ernährungs&shy;Coaching"
                text="Vereinbare jetzt dein kostenfreies Erstgespräch zum Ernährungscoaching. Gemeinsam finden wir die perfekte Ernährung für Dich und Deinen Körper."
                usps={["100% individuell", "Gesundes Abnehmen", "Vegane Ernährung für Sportler", "Verbesserung deiner Gesundheit"]}
                buttonText="kostenfreies Erstgespräch vereinbaren"
                buttonLink="dialog"
                targetDialog="DialogKontakt"
                backgroundImg="/img/StartseiteLandingBlock_4.webp"
                alignBox="left"
                id={blockControlsData[0].blockId}
            />
            <ContentBlock2
                icon="/img/Leistungen_Ernaehrungscoaching.svg"
                titel="Ernährungs&shy;coaching"
                subTitel="Pflanzliche Küche für ein gesundes Leben"
                text="Du möchtest endlich eine gesunde Routine in deinem Ernährungsverhalten etablieren? Du fühlst dich abgeschlagen und deine Vitalität lässt zu wünschen übrig? Vielleicht haben sich auch ein paar Kilo zu viel eingeschlichen und Diäten bringen nichts? Dann lade ich dich herzlich zu einem Kennenlerngespräch ein, um gemeinsam deine persönlichen Bedürfnisse und Hürden zu erörtern und einen möglichen Weg zu deinem Ziel zu besprechen und somit endlich langfristige Ergebnisse für dein Leben zu erhalten."
                zitat="Du bist ortsungebunden, wir können das Gespräch und die gesamte Beratung online oder persönlich durchführen."
                id={blockControlsData[1].blockId}
            />
            <div className="w-100" id={blockControlsData[2].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <h2 className="h4 h4--spaceing h4--border h4--center mb-7">Ich biete Ernährungs&shy;coaching in den folgenden Formen an</h2>
                        <div className="row w-100 mb-4">
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img
                                    src="/img/Content_PersonalTraining.svg"
                                    link="Personal Training Icon"
                                    alt="Personal Training Icon"
                                    className="contentSpecItem mb-4"
                                />
                                <span className="d-block text-center">Personal Training</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img
                                    src="/img/Content_GruppenTraining.svg"
                                    link="Gruppen Training Icon"
                                    alt="Gruppen Training Icon"
                                    className="contentSpecItem mb-4"
                                />
                                <span className="d-block text-center">Gruppen Training</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img
                                    src="/img/Content_FirmenFitness.svg"
                                    link="Firmen Fitness Icon"
                                    alt="Firmen Fitness Icon"
                                    className="contentSpecItem mb-4"
                                />
                                <span className="d-block text-center">Firmen Fitness</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_Workshops.svg" link="Workshops Icon" alt="Workshops Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Workshops</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_Vortraege.svg" link="Vorträge Icon" alt="Vorträge Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Vorträge</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-8 mb-4">
                                <h3 className="h4 h4--spaceing mb-4">Ernährungs&shy;beratung</h3>
                                <p className="mb-4">
                                    Das Ernährungscoaching wird dir die Möglichkeit geben, in deinem Tempo und auf deine Weise zu einem gesünderen Lebensstil zu
                                    finden. Bei gemeinsamen regelmäßigen Gesprächen finden wir heraus was deine Ziele sind und wo die Ursache für deine aktuelle
                                    Situation liegt. Nach einer ausführlichen Anamnese, Analyse deines Essverhaltens, Kühlschrankanalyse erarbeiten wir
                                    gemeinsam einen Plan Schritt für Schritt festgelegte Teilziele zu erreichen. Gemeinsames Einkaufen und Kochen wird dir
                                    helfen, schnell neue Verhaltensweisen in dein Leben zu etablieren. Ich freue mich auf die neuen Herausforderungen und
                                    unseren Weg zu deinem Ziel.
                                </p>
                                <h4 className="d-block fw-bold mb-2 fs-5">Kostenfreis Erstgespräch</h4>
                                <p className="mb-4">
                                    Für den bestmöglichen Erfolg, werden wir gemeinsam in einem kostenfreien Erstgespräch über deine Bedürfnisse sprechen, um
                                    diese zu bestimmen. Wenn so weit alles passt und die Chemie stimmt vereinbaren wir einen Termin zum für deinen Start.
                                </p>
                                <h4 className="d-block fw-bold mb-3 fs-5">Deine Vorteile:</h4>
                                <ul className="ul--usp">
                                    <li className="color-primary-dark">Du bekommst deine persönliche Coachin</li>
                                    <li className="color-primary-dark">Ich stehe dir immer mit einem offenen Ohr, Rat und Tat zur Seite</li>
                                    <li className="color-primary-dark">
                                        Gemeinsam erstellen wir langfristige Pläne um deine Ernährung unkompliziert und alltagstauglich zu gestalten
                                    </li>
                                    <li className="color-primary-dark">
                                        Du wirst dein Wissen zum Thema Gesundheit fundamental erweitern um eigenständig umzusetzen und beizubehalten, was wir
                                        gemeinsam erarbeiten
                                    </li>
                                </ul>
                                <span className="cursor-pointer" onClick={() => openDialog("DialogKontakt")}>
                                    <span className="fw-bold">Los geht's:&nbsp;</span>
                                    jetzt kostenloses Erstgespräch vereinbaren
                                </span>
                            </div>
                            <div className="col-12 col-md-4 mb-4">
                                <img src="/img/Content_Ernaehrungscoaching.webp" alt="Ernährungscoaching Foto" className="w-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100" id={blockControlsData[3].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <h2 className="h4 h4--spaceing h4--border h4--center mb-7">Die positive Wirkung einer gesunden Ernährung</h2>
                        <div className="row w-100">
                            <div className="col-12 col-md-6">
                                <div className="div--usp">
                                    <i className="bi bi-bar-chart"></i>
                                    <span>Fitness kräftigt und dehnt die Muskulatur</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-heart-pulse"></i>
                                    <span>Löst Verspannung und Blockaden und hilft damit bei Schmerzen</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-person-check-fill"></i>
                                    <span>Verbessert die Durchblutung</span>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="div--usp">
                                    <i className="bi bi-person-dash"></i>
                                    <span>Verbessert die Konzentrations- und Leistungsfähigkeit</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-flower1"></i>
                                    <span>Hilft bei Verdauungsproblemen</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-brightness-high"></i>
                                    <span>Vertieft den Atem und schult das korrekte und ökonomische Atmen</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100" id={blockControlsData[4].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <h2 className="h4 h4--spaceing h4--border h4--center mb-7">Wo findet die Ernährungs&shy;coaching statt?</h2>
                        <div className="row">
                            <div className="col-12">
                                <p className="mb-4">
                                    Die ist ein Beispiel für einen Blindtext, der auf deutsch geschrieben ist. Es gibt noch viele weitere Arten des Hauses,
                                    wobei die ersten Menschen heute nicht sind. Zudem sind Beispiele jetzt noch frei, denn man sollte bedenken, der Text macht
                                    wenig Sinn.
                                </p>
                                <span className="d-block fw-bold mb-2">Termine und Preise</span>
                                <p>
                                    Die ist ein Beispiel für einen Blindtext, der auf deutsch geschrieben ist. Es gibt noch viele weitere Arten des Hauses,
                                    wobei die ersten Menschen heute nicht sind. Zudem sind Beispiele jetzt noch frei, denn man sollte bedenken, der Text macht
                                    wenig Sinn.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ContentBlockRezeptCarousel" id={blockControlsData[5].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <h2 className="h4 h4--spaceing h4--border h4--center mb-7">Schnelle Rezepte zum nachmachen</h2>
                        <div className="row w-100">
                            {rezptSliderItems &&
                                rezptSliderItems.map((item, index) => {
                                    return (
                                        <div className="rezptSliderItem col-12 col-sm-6 col-md-3 mb-5" key={index}>
                                            <img
                                                src={directusURL + "/assets/" + item.Titelbild + "?fit=cover&width=400&height=400&quality=50"}
                                                alt={item.Titel}
                                                className="d-block w-100 mb-3"
                                            />
                                            <span className="rezptSliderItem__titel mb-2">{item.Titel}</span>
                                            <span className="rezptSliderItem__exzerpt mb-4">{item.Exzerpt}</span>
                                            <Link
                                                to={"/blog/" + item.slug}
                                                title={"zum Rezept" + item.Titel}
                                                className="btn-dark-primary d-block text-center w-100"
                                            >
                                                zum Rezept
                                            </Link>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100">
                <div className="wrapper">
                    <div className="content">
                        <h4 className="h4 h4--spaceing h4--border h4--center mb-7">Das sagen meine Kunden</h4>
                        <Reviews url={"/cronjobs/googleReviewsPersonal.json"} />
                    </div>
                </div>
            </div>
            <ContentBlockGallery
                id={blockControlsData[6].blockId}
                img1={"/img/Content_GalerieErnaehrungscoaching1.webp"}
                img2={"/img/Content_GalerieErnaehrungscoaching2.webp"}
                img3={"/img/Content_GalerieErnaehrungscoaching4.webp"}
                img4={"/img/Content_GalerieErnaehrungscoaching3.webp"}
            />
            <NewsletterFormular id={blockControlsData[7].blockId}></NewsletterFormular>
            <Footer id={blockControlsData[8].blockId} />
        </Fragment>
    );
}

export default Ernaehrungscoaching;
