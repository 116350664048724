import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import BlockControls from "../components/BlockControls";
import Blog__halfSizeThumb from "../components/Blog__halfSizeThumb";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScrollToTop from "../components/ScrollToTop";
import SocialIcons from "../components/SocialIcons";
import "./BlogPost.css";
import NewsletterFormular from "../components/NewsletterFormular";

function BlogPost() {
    const blockControlsData = [
        {
            titel: "Blog",
            blockId: "b1",
        },
        {
            titel: "Inhalt",
            blockId: "b2",
        },
        {
            titel: "weitere Beiträge",
            blockId: "b3",
        },
        {
            titel: "Newsletter",
            blockId: "newsletter",
        },
        {
            titel: "Kontakt",
            blockId: "b5",
        },
    ];

    const [schema, setSchema] = useState("light");
    const [schemaHeader, setSchemaHeader] = useState("light");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setSchema("dark");
            } else {
                setSchema("light");
            }
        });
    }, []);

    // fetch data

    const params = useParams();

    const [BlogData, setBlogData] = useState(null);
    const [blogPostsBottom, setBlogPostsBottom] = useState(null);

    const directusURL = "https://admin.carolinpfeffer.de";

    useEffect(() => {
        const options = { method: "GET" };

        fetch(directusURL + "/items/Blogbeitraege?filter[slug]=" + params["slug"] + "&filter[status]=published", options)
            .then((response) => response.json())
            .then((response) => {
                setBlogData(response["data"][0]);
                fetch(
                    directusURL +
                        "/items/Blogbeitraege?filter[status]=published&sort=-Datum&limit=2&filter[Kategorie]=" +
                        response["data"][0]["Kategorie"] +
                        "&page=2",
                    options
                )
                    .then((response) => response.json())
                    .then((response) => {
                        setBlogPostsBottom(response["data"]);
                    })
                    .catch((err) => console.error(err));
                if (response["data"].length === 0) {
                    console.error("Error: No Blog Data");
                    window.location = "/404";
                }
            })
            .catch((err) => {
                console.error(err);
                window.location = "/404";
            });
    }, [params]);

    return (
        <Fragment>
            <Helmet>
                <title>{BlogData && BlogData["Titel"] + "| Carolin Pfeffer"}</title>
                <meta name="description" content={BlogData && BlogData["Exzerpt"]}></meta>
                <meta name="keywords" content=""></meta>
                <meta name="author" content="Carolin Pfeffer"></meta>
            </Helmet>
            <ScrollToTop />
            <Header schema={schemaHeader} />
            <SocialIcons schema={schema} />
            <BlockControls blockControlsData={blockControlsData} schema={schema} />
            {BlogData && (
                <div
                    className="Blog__topBlock"
                    style={{
                        backgroundImage: "url(" + directusURL + "/assets/" + BlogData["Titelbild"] + ")",
                    }}
                >
                    <div className="topBlock__textContainer">
                        <span className="h2">{BlogData["Titel"]}</span>
                        <span className="d-block mb-2">
                            <span className="text-uppercase">{BlogData["Kategorie"]}</span>
                            <span>&nbsp;&#45;&nbsp;</span>
                            <span>{BlogData["Datum"].split("-").reverse().join(".")}</span>
                        </span>
                        <span>
                            <span>{BlogData["Exzerpt"].slice(0, 100)} ...&nbsp;</span>
                            <HashLink to={""} elementId={blockControlsData[1].blockId} title={"mehr über " + BlogData["Titel"] + " lesen"}>
                                mehr lesen
                            </HashLink>
                        </span>
                    </div>
                </div>
            )}
            {BlogData && (
                <article className="w-100" id={blockControlsData[1].blockId}>
                    <div className="wrapper">
                        <div className="content d-flex flex-column align-items-center">
                            <div className="row w-100">
                                <div className="col-12">
                                    <h2 className="h2">{BlogData["Titel"]}</h2>
                                    <span className="d-block mb-2">
                                        <span className="text-uppercase">{BlogData["Kategorie"]}</span>
                                        <span>&nbsp;&#45;&nbsp;</span>
                                        <span>{BlogData["Datum"].split("-").reverse().join(".")}</span>
                                    </span>
                                </div>
                            </div>
                            <div className="row w-100">
                                <div className="col-12">
                                    <div className="BlogPost">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: BlogData["Inhalt"],
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            )}
            {blogPostsBottom && blogPostsBottom.length > 0 ? (
                <div className="w-100" id={blockControlsData[2].blockId}>
                    <div className="wrapper">
                        <div className="content d-flex flex-column align-items-center">
                            <span className="h4 h4--spaceing h4--border h4--center mb-7">{blockControlsData[2].titel}</span>
                            <div className="row w-100">
                                {blogPostsBottom &&
                                    blogPostsBottom.map((post, index) => {
                                        return (
                                            <div className="col-12 col-md-6" key={index}>
                                                <Blog__halfSizeThumb
                                                    backgroundImage={directusURL + "/assets/" + post.Titelbild}
                                                    title={post.Titel}
                                                    category={post.Kategorie}
                                                    date={post.Datum}
                                                    exzerpt={post.Exzerpt}
                                                    link={post.slug}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                            {/* <span className="align-self-end mt-4 me-2" id="loadMoreNews" onClick={() => loadNextPageNews()}>
                            <span>ältere Beiträge laden</span>
                            &nbsp;
                            <i className="bi bi-arrow-down"></i>
                        </span> */}
                        </div>
                    </div>
                </div>
            ) : null}
            <NewsletterFormular id={blockControlsData[3].blockId}></NewsletterFormular>
            <Footer id={blockControlsData[4].blockId} />
        </Fragment>
    );
}

export default BlogPost;
